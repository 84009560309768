import { makeStyles } from '@material-ui/core';
import { addMiddleware, isStateTreeNode } from 'mobx-state-tree';
import React, { useState } from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { MainFocusArea } from 'src/myDayToday/MainFocusArea';
import { ScheduleWidget } from 'src/myDayToday/ScheduleWidget';
import { TopBar } from 'src/myDayToday/TopBar';
import { generatePopInEvent } from 'src/myDayToday/domain/generatePopInEvent';
import { shouldRenderEventCard } from 'src/myDayToday/domain/shouldRenderEventCard';
import { useProviderId } from 'src/myDayToday/domain/useProviderId';
import { useInterval } from 'src/myDayToday/hooks/useInterval';
import { GET_EVENT_CARDS_QUERY } from 'src/myDayToday/queries.gql';
import { Provider } from 'src/shared/stores/resource';
import useRootStore from 'src/stores/useRootStore';
import { ProviderRole } from 'src/stores/users/userType';
import { BoulderColors } from 'src/util/brand';

/**
 * The rate at which the MDT content should be refreshed
 */
const AUTO_REFRESH_TIME_MS = 15 * 1000;

export const MyDayToday = () => {
  const styles = useStyles();
  const providerId = useProviderId();
  const rootStore = useRootStore();
  const [currentTime, setCurrentTime] = useState(new Date());
  useInterval(() => setCurrentTime(new Date()), AUTO_REFRESH_TIME_MS);

  const { data, error, mutate, isLoading } = useTypedSWR(
    [
      GET_EVENT_CARDS_QUERY,
      {
        providerId,
      },
    ],
    { keepPreviousData: true, refreshInterval: AUTO_REFRESH_TIME_MS },
  );

  const { nextEvent } = data?.staff_myDayTodayEventCards ?? {};
  const teamRole = (rootStore.auth.user as Provider)?.teamRole as ProviderRole;

  const popinEvent =
    !error && nextEvent && shouldRenderEventCard(nextEvent, teamRole)
      ? generatePopInEvent(nextEvent)
      : null;

  if (!providerId) {
    return <div className={styles.container}>No provider ID found</div>;
  }

  // Refetch the MDT card data when an event has been updated
  // so we can update the display of any newly assigned pop-in
  // event on the calendar widget
  if (isStateTreeNode(rootStore)) {
    addMiddleware(
      rootStore,
      (action, next) => {
        if (action.name === 'eventChanged') {
          setImmediate(() => mutate());
        }

        return next(action);
      },
      true,
    );
  }

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <TopBar currentTime={currentTime} eventCardsData={data} />
        <div className={styles.bottom}>
          <ScheduleWidget
            currentTime={currentTime}
            providerId={providerId}
            popinEvent={popinEvent}
          />
          <MainFocusArea
            currentTime={currentTime}
            data={data}
            error={error}
            isLoading={isLoading}
            mutate={mutate}
            providerId={providerId}
          />
        </div>
      </div>
    </main>
  );
};

const useStyles = makeStyles({
  main: {
    height: 'calc(100vh - 64px)',
    backgroundColor: BoulderColors.Gray1,
    overflow: 'auto',
  },
  container: {
    boxSizing: 'border-box',
    fontFamily: '"Nunito", "Nunito Sans"',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: 24,
    gap: 51,
  },
});
