import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import { RouterView } from 'mobx-state-router';
import React, { Suspense, useCallback } from 'react';

import EncounterSubmissions from 'src/claims/encounterSubmissions';
import PatientSubmissions from 'src/claims/patientSubmissions';
import InterstitialLandingPage from 'src/components/app/interstitialLandingPage';
import PatientJoinCallSubscription from 'src/components/general/PatientJoinCallSubscription';
import VideoEndDialog from 'src/components/general/videoEndDialog/VideoEndDialog';
import AutomationPage from 'src/components/pages/automation';
import ClaimsPage from 'src/components/pages/claims';
import ErxPage from 'src/components/pages/erx';
import { SideVisitContextProvider } from 'src/components/pages/eventShow/SideVisitContext';
import EventShow from 'src/components/pages/eventShow/eventShow';
import EventVc from 'src/components/pages/eventVc';
import InquiriesPage from 'src/components/pages/inquiries';
import CreatePebbleDialog from 'src/components/pages/pageElements/createPebbleDialog';
import EditEventDialog from 'src/components/pages/pageElements/editEventDialog';
import PatientCreatePage from 'src/components/pages/patientCreate';
import PatientExportPage from 'src/components/pages/patientExport';
import PatientShowPage from 'src/components/pages/patientShow';
import { PatientShowContextProvider } from 'src/components/pages/patientShow/PatientShowContext';
import PatientsPage from 'src/components/pages/patients';
import PebblesPage from 'src/components/pages/pebbles';
import ProviderCreatePage from 'src/components/pages/providerCreate';
import ProviderEditPage from 'src/components/pages/providerEdit';
import ProviderShowPage from 'src/components/pages/providerShow';
import ProvidersPage from 'src/components/pages/providers';
import SmsCommunicationsPage from 'src/components/pages/smsCommunications';
import ZendeskPatient from 'src/components/pages/zendesk/ZendeskPatient';
import ClinicScheduleLogs from 'src/dropInClinic/ClinicScheduleLogs';
import { DropInClinic } from 'src/dropInClinic/DropInClinic';
import { DropInClinicSection } from 'src/dropInClinic/types';
import { LastPatientVisitContextProvider } from 'src/events/LastPatientVisitContext';
import { MyDayToday } from 'src/myDayToday/MyDayToday';
import LazyLoadingSpinner from 'src/nightingale/components/common/LazyLoadingSpinner/LazyLoadingSpinner';
import { PebblesUpdateContextProvider } from 'src/pebbles/PebblesUpdateContext';
import PebblePage from 'src/pebbles/components/PebblePage';
import PharmacyRecommendations from 'src/pharmacyRecommendations/PharmacyRecommendations';
import type { EventInstance } from 'src/stores/models/event';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';
import WaitlistPromotionPage from 'src/waitlist/waitlistPromotion';

const viewMap = {
  automation: <AutomationPage />,
  claims: <ClaimsPage />,
  createPatient: <PatientCreatePage />,
  createProvider: <ProviderCreatePage />,
  dropInClinicRequests: <DropInClinic activeSection={DropInClinicSection.Queue} />,
  dropInClinicScheduleLogs: <ClinicScheduleLogs />,
  editProvider: <ProviderEditPage />,
  encounterSubmissions: <EncounterSubmissions />,
  erx: <ErxPage />,
  pharmacyRecommendations: <PharmacyRecommendations />,
  eventVc: <EventVc />,
  inquiries: <InquiriesPage />,
  interstitialLandingPage: <InterstitialLandingPage />,
  myDayToday: <MyDayToday />,
  notFound: <Typography variant="h6">404</Typography>,
  patientExport: <PatientExportPage />,
  patients: <PatientsPage />,
  patientSubmissions: <PatientSubmissions />,
  pebble: <PebblePage />,
  pebbles: <PebblesPage />,
  providers: <ProvidersPage />,
  showEvent: <EventShow />,
  showPatient: <PatientShowPage />,
  showProvider: <ProviderShowPage />,
  sendTexts: <SmsCommunicationsPage />,
  waitlistPromotion: <WaitlistPromotionPage />,
  zendeskPatient: <ZendeskPatient />,
};

const Body: React.FC<{ rootStore: RootStore }> = ({
  rootStore: {
    routerStore,
    events: { cancelEditEvent, event, eventFromModal, edit, fixedAttendee, saveEvent },
    pebbles: {
      domain: { createPebble, list },
      ui: { createDialogOpen, cancelCreateDialog, createDialogDefaults, submitCreateDialog },
    },
  },
}) => {
  const handleCancelEditEvent = useCallback(() => cancelEditEvent(), [cancelEditEvent]);
  const handleSaveEvent = useCallback(
    (target: Partial<EventInstance>) => saveEvent(target, true),
    [saveEvent],
  );
  const handleOnNavigate = useCallback(
    (target: EventInstance) => {
      routerStore.goTo('showEvent', {
        params: {
          id: target.id,
        },
      });
    },
    [routerStore],
  );

  return (
    <Suspense fallback={<LazyLoadingSpinner />}>
      <PebblesUpdateContextProvider>
        <SideVisitContextProvider>
          <LastPatientVisitContextProvider>
            <PatientShowContextProvider>
              {/* @ts-expect-error mobx-state-router's typedefs are wrong */}
              <RouterView routerStore={routerStore} viewMap={viewMap} />
            </PatientShowContextProvider>
          </LastPatientVisitContextProvider>
        </SideVisitContextProvider>
        {(eventFromModal || event) && edit && (
          <EditEventDialog
            item={eventFromModal || event}
            fixedAttendee={fixedAttendee}
            onCancel={handleCancelEditEvent}
            onSave={handleSaveEvent}
            onNavigate={handleOnNavigate}
          />
        )}
        {createDialogOpen && (
          <CreatePebbleDialog
            onCancel={() => cancelCreateDialog()}
            onSubmit={async model => {
              // onSubmit expects this function to return a promise, so await the creation
              // even though we don't do anything with it here
              await submitCreateDialog(() => createPebble(model));

              // Only refresh the list if we're already on pebbles. We intentionally don't
              // await this load because it's not necessary - it can finish while onSubmit
              // finishes up it's own stuff.
              if (routerStore.routerState.routeName === 'pebbles') {
                list.load();
              }
            }}
            defaultValues={createDialogDefaults as unknown as any}
          />
        )}
      </PebblesUpdateContextProvider>
      <PatientJoinCallSubscription />
      <VideoEndDialog />
    </Suspense>
  );
};

export default inject<typeof Body>('rootStore')(observer(Body));
