import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';

import CancelEvent from 'src/components/forms/resources/cancelEvent';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { useInterval } from 'src/dropInClinic/hooks/useInterval';
import { EVENT_STATUSES } from 'src/shared/util/events';

const useTimerPanelStyles = makeStyles(() => ({
  timer: {
    position: 'absolute',
    right: 40,
    top: 40,
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 8,
    padding: 16,
    '& .time-value': {
      fontSize: '2rem',
      textAlign: 'center',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const padSeconds = seconds => (seconds < 10 ? `0${seconds}` : String(seconds));

const formatTimestampDifference = (startTime: number, currentTime: number): string => {
  const minutes: number = Math.floor((currentTime - startTime) / (60 * 1000));
  const seconds: number = Math.floor((currentTime - startTime) / 1000 - minutes * 60);

  const formattedMinutes =
    Number.isNaN(minutes) || startTime > currentTime ? '--' : String(minutes);
  const formattedSeconds =
    Number.isNaN(seconds) || startTime > currentTime ? '--' : padSeconds(seconds);

  return `${formattedMinutes}:${formattedSeconds}`;
};

const cancelEvent = ({ apolloClient, eventId, scheduleChangeNotes, scheduleChangeReason }) =>
  apolloClient.mutate({
    mutation: gql`
      mutation ($data: EventInput!, $id: ID!) {
        updateEvent(data: $data, id: $id) {
          id
        }
      }
    `,
    variables: {
      data: {
        scheduleChangeNotes,
        scheduleChangeReason,
        status: EVENT_STATUSES.CANCELED,
      },
      id: eventId,
    },
  });

type TimerPanelProps = {
  eventId: string;
  startTime: number;
};

export const TimerPanel = ({ eventId, startTime }: TimerPanelProps) => {
  const classes = useTimerPanelStyles();

  const [currentTime, setCurrentTime] = useState<number>(0);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [cancellationError, setCancellationError] = useState<string | null>(null);

  useInterval(() => setCurrentTime(new Date().valueOf()), 1000);

  const { apolloClient } = useContext(ApolloClientContext);

  const onSubmit = async values => {
    if (!apolloClient) {
      return;
    }

    setCancellationError(null);

    cancelEvent({ apolloClient, eventId, ...values })
      .then(() => setShowCancelModal(false))
      .catch((error: Error) => {
        setCancellationError(String(error));
      });
  };

  return (
    <div className={classes.timer}>
      <div className="time-value">{formatTimestampDifference(startTime, currentTime)}</div>
      <Button variant="contained" color="primary" onClick={() => setShowCancelModal(true)}>
        Cancel Visit
      </Button>

      <Dialog
        open={showCancelModal}
        maxWidth="md"
        fullWidth
        onClose={() => setShowCancelModal(false)}
      >
        <DialogTitle>Cancel Event</DialogTitle>
        <DialogContent>
          {cancellationError && (
            <ErrorAlert title="Unable to cancel this event" message={cancellationError} />
          )}
          <CancelEvent
            item={{}}
            onCancel={() => setShowCancelModal(false)}
            onSave={onSubmit}
            classes={{
              buttons: classes.buttons,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
